<template>
  <div class="view-login auth mb-5 pb-5 pb-lg-0">
    <div class="container pt-lg-0 mb-5 mb-lg-0">
      <div class="row pt-lg-5">
        <div class="col col-lg-5 mx-auto">
          <form @submit.prevent="submit">
            <div class="card-body py-lg-5 px-3 px-lg-5">
              <div class="text-center">
                <img
                  width="181"
                  height="97.27"
                  src="@/assets/images/logo.png"
                />
              </div>
              <h2 class="text-center mb-4 text-uppercase">
                Criar uma conta
              </h2>

              <form-group
                v-model="form.name"
                id="name"
                isLogin
                label="Nome completo"
                placeholder="Digite seu nome"
                :errors="errors.name"
                :show-error-message="false"
                @change="resetErrors"
              />

              <form-group
                v-model="form.email"
                id="email"
                type="email"
                isLogin
                label="Email"
                placeholder="Digite seu e-mail"
                :errors="errors.email"
                :show-error-message="false"
                @change="resetErrors"
              />
              <label for="phone-row" class="mt-3 mb-n3">
                Número de telefone
              </label>
              <div id="phone-row" class="row">
                <div class="col-4">
                  <form-group
                    v-model="form.ddd"
                    id="ddd"
                    isLogin
                    type="ddd"
                    placeholder="DDD"
                    :errors="errors.ddd"
                    :show-error-message="false"
                    @change="resetErrors"
                  />
                </div>
                <div class="col-8">
                  <form-group
                    v-model="form.phone"
                    id="phone"
                    isLogin
                    masked
                    mask="# ####-####"
                    placeholder="9 9999-9999"
                    :errors="errors.phone"
                    @change="resetErrors"
                  />
                </div>
              </div>

              <form-group
                v-model="form.password"
                :is-login="true"
                id="password"
                type="password"
                label="Senha"
                placeholder="Digite a sua senha"
                :errors="errors.password"
                :show-error-message="false"
                @change="resetErrors"
              />
              <form-group
                v-model="form.password_confirm"
                :is-login="true"
                id="password"
                type="password"
                label="Confirmar senha"
                placeholder="Digite a mesma senha"
                :errors="errors.password_confirm"
                :show-error-message="false"
                @change="resetErrors"
              />
              <div class="text-center py-4">
                <button
                  type="submit"
                  :disabled="!isValidFields"
                  class="btn btn-primary btn-block"
                >
                  <loading :show="showLoading">
                    Criar uma conta
                  </loading>
                </button>
              </div>
              <p class="small text-justify">
                Ao se registrar, você concorda com nossos
                <a
                  href="/termos-de-uso/"
                  target="_blank"
                  class="text-primary text-decoration-underline"
                  ><u>Termos de Uso</u></a
                >
                e
                <a
                  href="/politicas-de-privacidade/"
                  target="_blank"
                  class="text-primary text-decoration-underline"
                  ><u>Política de Privacidade</u></a
                >, incluindo o uso de cookies e o envio de comunicações.
              </p>
              <p class="text-center mb-5">
                <router-link class="btn btn-sm" :to="{ name: 'login' }">
                  <span class="font-weight-normal mr-2">Já tem uma conta?</span>
                  <span class="text-primary font-weight-bold"
                    >Faça o seu login.</span
                  >
                </router-link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formFields,
  removeMask,
  firstLetterUppercase
} from "@/functions/helpers";
import { authMixin } from "@/mixins";
import get from "lodash/get";

export default {
  name: "register",
  mixins: [authMixin],
  data() {
    return {
      ...formFields(
        [
          "name",
          "email",
          "ddd",
          "phone",
          "password",
          "password_confirm",
          "agreed"
        ],
        {
          agreed: true
        }
      ),
      showLoading: false,
      forgotDialog: false,
      isValidFields: true
    };
  },
  computed: {
    errorMsg() {
      return (
        get(this.errors, "email[0]") ||
        get(this.errors, "password[0]") ||
        get(this.errors, "non_field_errors[0]")
      );
    }
  },
  watch: {
    password() {
      this.validateFields();
    },
    email() {
      this.validateFields();
    }
  },
  methods: {
    validateFields() {
      const isValidEmail = !!this.email && /\S+@\S+\.\S+/.test(this.email);
      const isValidPassword = !!this.password && this.password.length > 0;
      this.isValidFields = isValidEmail && isValidPassword;
    },
    resetErrors() {
      this.errors = {
        email: [],
        password: [],
        non_field_errors: []
      };
    },
    goToForgotPassword() {
      this.$router.push("/password-recovery");
    },
    submit() {
      if (!this.form.name?.length) {
        this.$message.warning("Por favor, digite seu nome");
        return;
      }

      if (!this.form.email?.length) {
        this.$message.warning("Por favor, digite seu e-mail");
        return;
      }

      if (!this.form?.ddd.length || !this.form?.phone.length) {
        this.$message.warning("Por favor, digite o número com DDD");
        return;
      }

      if (!this.form?.password.length || !this.form?.password_confirm.length) {
        this.$message.warning("Por favor, digite sua senha e a confirmação");
        return;
      }

      if (this.form.password !== this.form.password_confirm) {
        this.$message.warning(
          "As senhas não são iguais, por favor, verifique as senhas digitadas."
        );
        return;
      }

      this.form.name = firstLetterUppercase(this.form.name);
      this.showLoading = true;
      this.resetErrors();
      this.$store
        .dispatch("user/create", {
          ...this.form,
          phone: removeMask(`${this.form.ddd + this.form.phone}`)
        })
        .then(() => {
          this.$message.success(
            "Sua conta foi criada! Faça login para começar!"
          );
          this.$router.push({ name: "login" });
        })
        .catch(this.handleErrors)
        .finally(this.handleFinally);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.view-login {
  width: 100%;

  .btn-sm {
    font-size: 14px;
    font-weight: 900;
  }
  label {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.015em;
    color: #fff;
  }
}
</style>
